import React from "react"
import { Link } from "gatsby"
import DispayLeafletMap from "../../components/displaymap"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Carousel from "nuka-carousel"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons"
import LeafletMap from "../../components/leafletmap"
import { useSelector } from "react-redux"
import slugify from "slugify"

import groceriesIcon from "../../images/icon/paper-bag.svg"

export const RondeauPage = props => {
  const data = useStaticQuery(graphql`
    query imagesRondeau {
      rondeau_0: file(relativePath: { eq: "producteur/ferme-rondeau.webp" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      rondeau_1: file(relativePath: { eq: "producteur/rondeau_1.jpg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      rondeau_2: file(relativePath: { eq: "producteur/rondeau_2.jpg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      rondeau_3: file(relativePath: { eq: "producteur/rondeau_3.jpg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      rondeau_4: file(relativePath: { eq: "producteur/rondeau_4.jpg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  const panier = useSelector(state => state.panierR)
  const latitude = 47.089856
  const longitude = 6.271112

  const state = {
    markers: [{ key: "marker1", position: [latitude, longitude] }],
    latlng: {
      lat: latitude,
      lng: longitude,
    },
    zoom: 9,
  }

  return (
    <Layout>
      <SEO title="Ferme du Rondeau" />
      <section className="hero pattern-zigzag-light" style={{ marginTop: -4 }}>
        <div className="hero-body">
          <div className="container has-text-centered">
            <h1 className="title dreamland is-size-1">La ferme du Rondeau</h1>
          </div>
        </div>
      </section>

      <section className="pt-5 mt-5">
        <div className="container is-fluid">
          <div className="columns">
            <div className="column is-one-third">
              <h2 className="almond is-size-3 has-text-centered">
                Lavans-Vuillafans (25580)
              </h2>
              {typeof window !== "undefined" && (
                <DispayLeafletMap
                  style={{ height: "600px" }}
                  markers={state.markers}
                  center={state.latlng}
                  zoom={state.zoom}
                />
              )}
            </div>
            <div className="column">
              <div>
                <Carousel
                  autoplay={true}
                  autoplayInterval={2500}
                  transitionMode={"fade"}
                  wrapAround={true}
                  renderCenterLeftControls={({ previousSlide }) => (
                    <button
                      onClick={previousSlide}
                      style={{ backgroundColor: "transparent", border: "none" }}
                    >
                      <FontAwesomeIcon
                        className=""
                        icon={faChevronLeft}
                        size="3x"
                        color={"whitesmoke"}
                      />
                    </button>
                  )}
                  renderCenterRightControls={({ nextSlide }) => (
                    <button
                      onClick={nextSlide}
                      style={{ backgroundColor: "transparent", border: "none" }}
                    >
                      <FontAwesomeIcon
                        className=""
                        icon={faChevronRight}
                        size="3x"
                        color={"whitesmoke"}
                      />
                    </button>
                  )}
                >
                  <Img
                    style={{ maxHeight: "500px" }}
                    fluid={data.rondeau_0.childImageSharp.fluid}
                  />
                  <Img
                    style={{ maxHeight: "500px" }}
                    fluid={data.rondeau_1.childImageSharp.fluid}
                  />
                  <Img
                    style={{ maxHeight: "500px" }}
                    fluid={data.rondeau_2.childImageSharp.fluid}
                  />
                  <Img
                    style={{ maxHeight: "500px" }}
                    fluid={data.rondeau_3.childImageSharp.fluid}
                  />
                  <Img
                    style={{ maxHeight: "500px" }}
                    fluid={data.rondeau_4.childImageSharp.fluid}
                  />
                </Carousel>
              </div>
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <div>
                <section className="m-2 has-text-justified is-size-4">
                  <div className="box" style={{background: "#ffff76"}}>
                    <p className="is-4">
                      Depuis 1961, la ferme du Rondeau pratique l'agriculture
                      biologique.
                    </p>

                    <p className="is-4">
                      Depuis 3 générations, nous élevons des animaux (chèvres
                      alpines, vaches laitières, sangliers, porcs, poules..),
                      cultivons nos terres, transformons et vendons nos
                      productions en direct et localement. C'est aussi la
                      première ferme à avoir, en 2004, adhéré à Agro Bio Conso,
                      réseau qui rapproche les producteurs et les consommateurs.
                    </p>

                    <p className="is-4">
                      Ce mode de distribution nous assure un lien privilégié
                      avec nos clients, désireux de consommer bio et local. De
                      nombreuses références de fromages de chèvre et vaches,
                      charcuteries et légumes de la ferme sont disponibles sur
                      notre bon de commande !
                    </p>
                    <p className="is-4">
                      Savourez des produits sains, de qualité, tout près de chez
                      vous !!
                    </p>
                    <p className="is-4">
                      Au plaisir de vous servir, à bientôt, L'équipe du Rondeau.
                    </p>
                  </div>
                </section>
              </div>
            </div>
            <div className="column has-text-centered">
              {(panier.zone === null && panier.pickupPoint === null) || (panier.zone !== 2 && panier.zone !== 11) ? (
                <Link to="#selectPoint">
                  {" "}
                  <button className="button is-warning mt-4">
                    Sélectionnez un point de retrait pour voir les produits
                  </button>
                </Link>
              ) : (
                <Link
                  to={
                    `/producteur/` +
                    (slugify(props.location.search.replace(/[^0-9]/g, "")) || 2) +
                    `-rondeau/produits`
                  }
                >
                  <button className="button is-success is-light is-large mt-4 dreamland is-size-3">
                    <img
                      src={groceriesIcon}
                      style={{
                        width: 40,
                        height: 40,
                        position: "relative",
                        top: 12,
                        marginRight: 15,
                      }}
                      alt="Produits"
                    />
                    Voir les produits
                  </button>
                </Link>
              )}
              <span
                className="mt-5 ml-4 almond is-size-3"
                style={{ display: "block" }}
              >
                Ce producteur livre aux points de dépôt suivants :
              </span>
              {typeof window !== "undefined" && (
                <section id="selectPoint" className="mt-4 producer">
                  <LeafletMap producteur={[2, 11]} />
                </section>
              )}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default RondeauPage
